@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ecf1f5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-pop:hover {
  transform: scale(1.005);
}

#TeamAutoCompleteInput {
  border-width: 1px;
}

#TeamAutoCompleteInput input {
  border: none;
}

#UserAutoCompleteInput {
  border-width: 1px;
}

#UserAutoCompleteInput input {
  border: none;
}

#MessageTable td>p {
  overflow: hidden;
  max-width: 55ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#NotificatoinTable td>p {
  overflow: hidden;
  max-width: 55ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chakra-divider:nth-last-of-type(6) {
  display: none;
}

.slide-player ul,
.slide-player ol {
  margin: 15px 15px 15px 20px;
  padding: 0
}

.task-report ul,
.task-report ol {
  margin: 0px 15px 0px 13px;
  padding: 0
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto;
}

.combobox {
  font-size: 11pt !important;
  display: inline !important;
  color: #2D3748 !important;
  font-weight: 500 !important;
  padding: "5px" !important;
  background-color: transparent !important;
}

.combobox:focus-within {
  outline: none;
}

.is-sticky {
  position: fixed;
  top: 20px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

#TrainingNotesMD>ul {
  padding: 0 0 0 15px !important;
}

#TrainingNotesMD>ol {
  padding: 0 0 0 15px !important;
}

#TrainingAnswerMD>ul {
  padding: 0 0 0 15px !important;
}

#TrainingAnswerMD>ol {
  padding: 0 0 0 15px !important;
}

#TaskNotesMD,
#PageNotesMD {
  padding: 0 !important;
  margin: 10px 0 0 0 !important;
  line-height: 1em !important;
  font-size: 14px !important;
}

#TaskNotesMD ul,
#TaskNotesMD ol,
#PageNotesMD ul,
#PageNotesMD ol {
  margin: 0 0 0 15px !important;
  padding: 0 !important;
}

#TaskNotesMD>ul>li,
#TaskNotesMD>ol>li,
#PageNotesMD>ul>li,
#PageNotesMD>ol>li {
  margin: 0 0 0 15px !important;
  padding: 0 !important;
}

#TaskNotesMD>a,
#PageNotesMD>a {
  color: #37a169 !important;
}

#TaskNotesMD>a:hover,
#PageNotesMD>a:hover {
  color: #37a169 !important;
  text-decoration: underline !important;
}

.chakra-slide {
  overflow-y: scroll;
  width: 235px !important;
}

.disabled-row {
  pointer-events: none;
  opacity: 0.5;
}

input:placeholder-shown {
  color: #2d3748 !important;
  font-size: 11pt !important;
  font-weight: 500 !important;
}

.actionDueDateInput {
  color: #2d3748 !important;
  font-weight: 500 !important;
}

#ReleaseNotes {
  width: 100%;
  height: calc(100vh - 50px);
  border: solid 1px #ccc;
  border-radius: 4px;
}

.test {
  background-color: white !important;
}

.always-show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.always-show-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .3);
}

.noMargin {
  margin: 0px !important;
}

.chakra-collapse {
  width: 100% !important;
}

.combobox-options {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.menu-button-wrapper:hover .inner-button {
  background-color: #248451 !important;
}